import React,{Component} from 'react';
import PreAccess from '../preAccess/preAccess';
import Terminal from '../terminal/terminal';
import Cards from '../cards/cards';
import Social from '../social_media/social_media';
import styled from 'styled-components';

const Container = styled.div`
display: flex;
  /*align-items: center;*/
  /*background-size: cover;*/
  flex-direction: column;
  min-width: 100vw;
  min-height: 100vh;
`

class App extends Component {
  render(){
  
  return (
    <Container className="App">
      <PreAccess />
      <Terminal />
      <Cards/>
      <Social/>
    </Container>
    
  );
  }
}

export default App;
