import './cards.css'
import logo from "../assets/learning2.jpg"
import kali from "../assets/kali3.jpg"
import coding from "../assets/coding.jpg"
import Card2 from './cards2';

function Terminal() {
  let url = "https://www.hackthebox.com/";
  let url2 = "https://tryhackme.com/"

    return(
  <div className= 'container'>
    <Card2 photoSrc={kali} txt={<h4>Kali Linux and security enthusiast. Engaged in <a href={url}>HACKTHEBOX</a> and  <a href={url2}>TryHackMe. </a> Always looking for a vulnerability 🕵️‍♂️</h4>}/>
    <Card2 photoSrc={coding} txt={<h4>{"</>"} Coding and problem solving are things that keeps me committed. Programming skills in Python, Java, React, Android and Haskell 💻  </h4>}/>
    <Card2 photoSrc={logo} txt={<h4>My approaches to learning are triggered by curiosity, discipline and initiative. I love maths, observation and investigation  ✍ </h4>}/>
  </div>
  )
    
}

export default Terminal;
