import styled from 'styled-components'
import './cards.css'

let Card = styled.div`
position: relative;
max-width: 300px;
height: 235px;
background-color: rgb(213, 202, 226);
padding: 10px 20px;
row-gap: -30px;
display: flex;
flex-direction: column;
box-shadow: 0 5px 30px rgb(252 251 253);
transition: 0.4s ease-in-out;
border-radius: 37px;

  &:hover {
      height: 320px;

      .content {
        margin-top: 60px;
        visibility: visible;
        opacity: 1;
        transition-delay: 0.2s;
        z-index: 2;
      }
  }

  @media screen and (max-width: 1400px) {
      margin-bottom: 8rem;
  }
`

const StyledImgContainer = styled.div`
position: relative;
width: 260px;
height: 20px;
top: -45%;
box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
z-index: 3;
`

const StyledImg = styled.img`
margin-top: 25px;
max-width: 100%;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px; 
`

const StyledContent = styled.div`


position: relative;
top: 43px;
padding: 10px 15px;
color: #111;
text-align: center;
visibility: visible;
opacity: 0;
transition: 0.4s ease;
`

export default function Card2({photoSrc, txt}) {
    return(
        <Card>
            <StyledImgContainer>
                <StyledImg alt='kali' src= {photoSrc}/>
            </StyledImgContainer>
            <StyledContent className='content'>
                {txt}
            </StyledContent>
        </Card> 
    )
}