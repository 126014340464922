import './social_media.css'



function Social() {
    const url_linkedin = "https://www.linkedin.com/in/bashar-oumari-19268b77/"
    const url_github = "https://github.com/BasharOumari"
    return(
   
    <div className="socialWrapper">
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
        <button onClick={()=>window.open(url_github)} className="btn" >
            <i className="fa fa-github" ></i>
        </button>
  
        <button onClick={()=>window.open(url_linkedin)}className="btn">
        <i className="fa fa-linkedin"></i>
        </button>

    </div>
            

        
       

    )
    
}

export default Social;




