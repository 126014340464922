import './terminal.css'

function Terminal() {
    return(
        <div className='Terminal'>
        <div className='fakeMenu'>
        <div className="fakeButtons fakeClose"></div>
        <div className="fakeButtons fakeMinimize"></div>
        <div className="fakeButtons fakeZoom"></div>
      </div>
      <div className="fakeScreen">
        <p className="line1">${'>'} My name is Bashar Oumari. </p>
        <p className="line2">&nbsp;&nbsp;&nbsp;I'm a Computer Scientist. </p>
        <p className="line3">&nbsp;&nbsp;&nbsp;Pursuing masters.</p>
        <p className="line4">&nbsp;&nbsp;&nbsp;Let's work together!<span className="cursor4">_&hearts;</span></p>
       
      </div>
      </div>

        
       

    )
    
}

export default Terminal;