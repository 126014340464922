import './preAccess.css'

function PreAccess() {
    return(
            <div className='whole'>
            <div className="stars"></div>
            <div className="twinkling"></div>
            <div className="clouds"></div>

            </div>

        
       

    )
    
}

export default PreAccess;